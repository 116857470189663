import React, { useEffect, useState } from "react";
import { Button, Image, Popconfirm, Table, Tooltip, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import styles from "./employee.module.css";
import { httpClient } from "../../config/config";
import { urls } from "../../config/urls";
import { Pointer } from "lucide-react";
import EmpNewData from "./EmpNewData";
import { isVisible } from "@testing-library/user-event/dist/utils";

function UploadImage({ editData }) {
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState();
    const [base64File, setBase64File] = useState();
    const [empNewData, setEmpNewData] = useState();
    const [showEmpNewData, setShowEmpNewData] = useState(false);

    useEffect(() => {
        if (editData) {
            httpClient({
                url: urls.employeePhotos + "/employee/" + editData.employeeId,
                method: "get",
            }).then((res) => {
                setBase64File(res.data?.data[0]?.filePath);
            });

            httpClient({ url: urls.employee_position + "/changes/" + editData.employeeId }).then(
                (res) => {
                    if (res.data.data?.employees?.length > 0) {
                        setEmpNewData(res.data.data?.employees[0]);
                    }
                }
            );
        }
    }, [editData]);

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const dummyRequest = ({ file, onSuccess }) => {
        // Simulate a request
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const beforeUpload = (file) => {
        setLoading(true);
        // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        // if (!isJpgOrPng) {
        //   message.error('You can only upload JPG/PNG files!');
        //   return Upload.LIST_IGNORE;
        // }

        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must be smaller than 2MB!");
            setLoading(false);
            return Upload.LIST_IGNORE;
        }

        setFileName(file.name);

        return getBase64(file)
            .then((base64) => {
                setLoading(false);
                setBase64File(base64.split(",")[1]);
            })
            .catch(() => {
                setLoading(false);
                message.error("Xəta baş verdi!");
            });
    };

    const handleSave = () => {
        const request_body = {
            employeeId: editData.employeeId,
            fileName: fileName,
            filePath: base64File,
        };

        httpClient({
            url: urls.employeePhotos,
            data: request_body,
            method: "post",
        }).then(() => {
            message.success("Fayl yadda saxlanıldı");
        });
    };

    return (
        <div className={styles.image_area}>
            <div className={styles.image_container} style={base64File && { border: "none" }}>
                {base64File ? (
                    <Image src={"data:image/png;base64, " + base64File} />
                ) : (
                    "Şəkil yoxdur"
                )}
            </div>

            <div className="space_between_items gap_20">
                <Upload
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    customRequest={dummyRequest}
                >
                    <Button disabled={!editData} icon={<UploadOutlined />}>
                        {loading ? "Yüklənir..." : "Yüklə"}
                    </Button>
                </Upload>
                <Button onClick={handleSave} disabled={!base64File}>
                    Yadda saxla
                </Button>
            </div>

            {empNewData && (
                <Button
                    style={{ background: "#FF5722", color: "#fff" }}
                    onClick={() => setShowEmpNewData(true)}
                >
                    Gözləyən vəzifə dəyişikliyi var
                </Button>
            )}

            <EmpNewData
                data={empNewData}
                isVisible={showEmpNewData}
                setIsVisible={setShowEmpNewData}
            />
        </div>
    );
}

export default UploadImage;
