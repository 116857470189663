import { Link, Route, Routes } from "react-router-dom";
import DashboardLayout from "./components/common/Layout/DashboardLayout";
import { Fragment, Suspense, useEffect } from "react";
import { auth, main_routes, routes } from "./components/routes/routes";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import ProtectedRoutes from "./pages/auth/ProtectedRoutes";
import Cookies from "js-cookie";
import { roleMenuRouteConfig } from "./pages/auth/roles";
import { getUserInfo } from "./utils/getUserInfo";
import "./App.css";
import "./override.css";

function App() {
    const isLoading = useSelector((state) => state.loader.isLoading);
    const isRoleLoading = useSelector((state) => state.loader.isRoleLoading);
    const roles = useSelector((state) => state.auth.roles);

    useEffect(() => {
        const token = Cookies.get("apf_access_token");
        if (token) {
            getUserInfo(token);
        } else {
            if (window.location?.pathname !== "/login") window.location.replace("/login");
        }
    }, []);

    const renderRoute = (route) => (
        <Fragment key={route.key}>
            <Route
                path={route.key}
                element={<Suspense fallback={<div>Loading...</div>}>{route.element}</Suspense>}
            />
            {route.children && route.children.map((sub) => renderRoute(sub))}
        </Fragment>
    );

    function filterRoutesByRole(userRoles, routes) {
        return routes.reduce((acc, route) => {
            const isRoleRestricted = Object.values(roleMenuRouteConfig).some((routesArray) =>
                routesArray.includes(route.key)
            );

            if (
                !isRoleRestricted ||
                userRoles.some((role) => roleMenuRouteConfig[role]?.includes(route.key))
            ) {
                const filteredRoute = {
                    ...route,
                    children: route.children ? filterRoutesByRole(userRoles, route.children) : [],
                };
                acc.push(filteredRoute);
            }
            return acc;
        }, []);
    }

    const accessibleRoutes = filterRoutesByRole(roles, [...routes, ...main_routes]);

    return (
        <Spin spinning={isLoading}>
            <Routes>
                <Route element={<ProtectedRoutes />}>
                    <Route path="/" element={<DashboardLayout />}>
                        {accessibleRoutes.map((route) => renderRoute(route))}
                    </Route>
                </Route>
                {auth.map((item) => (
                    <Fragment key={item.key}>
                        <Route path={item.key} key={item.key} element={item.element} />
                    </Fragment>
                ))}
                <Route
                    path="*"
                    key="*"
                    element={
                        <>
                            {isRoleLoading ? (
                                <></>
                            ) : (
                                <>
                                    <div>Səhifə tapılmadı</div> <Link to="/">Əsas Səhifə</Link>
                                </>
                            )}
                        </>
                    }
                />
            </Routes>
        </Spin>
    );
}

export default App;
