import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Select } from "antd";
import { httpClient } from "../../../config/config";
import { urls } from "../../../config/urls";
import ModalButtons from "../../../components/common/ModalButtons";
import { filterOption } from "../../../utils/commonUtils";
import { getAllData } from "../../../helpers/helperFunctions";

function AddModal({ isVisible, setIsVisible, editData, setEditData, getAll }) {
    const [measureTypes, setMeasureTypes] = useState([]);
    const [equipmentTypes, setEquipmentTypes] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        getAllData(urls.measureType_actives, setMeasureTypes);
        getAllData(urls.equipmentType_actives, setEquipmentTypes);
    }, []);

    const onCancel = () => {
        setIsVisible(false);
        setEditData();
        form.resetFields();
    };

    const onFinish = (values) => {
        let data = {
            name: values.name,
            measureTypeId: values.measureTypeId,
            equipmentTypeId: values.equipmentTypeId,
        };

        if (editData) {
            httpClient({ url: urls.equipments + "/" + editData.id, method: "put", data }).then(
                () => {
                    getAll(null, null, false, true);
                    onCancel();
                }
            );
        } else {
            httpClient({ url: urls.equipments, method: "post", data }).then(() => {
                getAll(null, null, true);
                onCancel();
            });
        }
    };
    useEffect(() => {
        if (editData) {
            form.setFieldsValue({
                name: editData.name,
                measureTypeId: editData.measureTypeId,
                equipmentTypeId: editData.equipmentTypeId,
            });
        }
    }, [editData]);
    return (
        <Modal
            title={"Vəsait siyahısı"}
            true
            open={isVisible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Form.Item
                    label="Ad"
                    name="name"
                    rules={[{ required: true, message: "Adı daxil edin" }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Ölçü vahidi"
                    name="measureTypeId"
                    rules={[{ required: true, message: "Ölçü vahidini seçin" }]}
                >
                    <Select
                        style={{ width: "100%" }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                    >
                        {measureTypes?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Vəsait növü"
                    name="equipmentTypeId"
                    rules={[{ required: true, message: "Vəsait növünü seçin" }]}
                >
                    <Select
                        style={{ width: "100%" }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                    >
                        {equipmentTypes?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <ModalButtons onCancel={onCancel} editData={editData} />
            </Form>
        </Modal>
    );
}

export default AddModal;
