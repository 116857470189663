import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import App from "./App";
import "./static/common.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ConfigProvider } from "antd";
import azAZ from 'antd/locale/az_AZ'
import "dayjs/locale/az"

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Router>
        <Provider store={store}>
            <ConfigProvider locale={azAZ}>
                <App />
            </ConfigProvider>
        </Provider>
    </Router>
);
