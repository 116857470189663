import { jwtDecode } from "jwt-decode";
import { httpClient } from "../config/config";
import { urls } from "../config/urls";
import { store } from "../redux/store";
import { login } from "../redux/slicers/authSlice";
import Cookies from "js-cookie";
import { roleLoading, roleReady } from "../redux/slicers/loaderSlice";

export const getUserInfo = async (token) => {
    store.dispatch(roleLoading());
    let roles = [];
    let decodedUser = jwtDecode(token);
    const timestamp = decodedUser?.exp;

    // Get the current UNIX timestamp
    const currentTimestamp = Math.floor(Date.now() / 1000);

    // Check if the timestamp has expired
    const hasExpired = currentTimestamp > timestamp;
    if (hasExpired) {
        Cookies.remove("apf_refresh_token");
        Cookies.remove("apf_access_token");
        window.location.replace("/login");
    }

    // decodedUser?.roles?.map((item) => roles.push(item?.roleName));

    let roleData = await httpClient({
        url: urls.users + "/getRoleAndPermissionByUserId/" + decodedUser?.userId,
        method: "get",
        type: "auth",
    });

    roleData.data.roles?.map((role) => roles.push(role.role_name));

    let userData = await httpClient({
        url: urls.users + "/" + decodedUser?.userId,
        method: "get",
        type: "auth",
    });
    let userPhoto = await httpClient({
        url: urls.employeePhotos + "/employee/" + decodedUser?.employeeId,
        method: "get",
    });
    store.dispatch(
        login({
            roles,
            userInfo: userData?.data,
            userPhoto: userPhoto?.data?.data[0]?.filePath,
        })
    );
    store.dispatch(roleReady());
};
