import { Modal } from "antd";
import React from "react";
import Equipments from "../Equipments/Equipments/Equipments";

function SelectEquipment({ isVisible, setIsVisible, setSelectedEqpm }) {
    const onCancel = () => {
        setIsVisible(false);
    };
    return (
        <Modal
            title={"Vəsait seçin"}
            open={isVisible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose={true}
            maskClosable={false}
            width="80%"
        >
            <Equipments
                setSelectedEqpm={setSelectedEqpm}
                isEqpmVisible={isVisible}
                setIsEqpmVisible={setIsVisible}
            />
        </Modal>
    );
}

export default SelectEquipment;
