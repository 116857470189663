import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const normalizeText = (text) => {
    return text.replace(/İ/g, "I").toLowerCase();
};

const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
};
const handleReset = (clearFilters) => {
    clearFilters();
};
export const getColumnSearchProps = (dataIndex, searchInput) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            style={{
                padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Input
                ref={searchInput}
                // placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{
                    marginBottom: 8,
                    display: "block",
                }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{
                        width: 90,
                    }}
                >
                    Axtar
                </Button>
                <Button
                    onClick={() => clearFilters && handleReset(clearFilters)}
                    size="small"
                    style={{
                        width: 90,
                    }}
                >
                    Sıfırla
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Bağla
                </Button>
            </Space>
        </div>
    ),
    filterIcon: (filtered) => (
        <SearchOutlined
            style={{
                color: filtered ? "#1677ff" : undefined,
            }}
        />
    ),
    onFilter: (value, record) => {
        const recordValue = record[dataIndex];
        if (recordValue == null) {
            return false; // or handle as needed
        }
        return normalizeText(recordValue.toString()).includes(normalizeText(value));
    },
    onFilterDropdownOpenChange: (visible) => {
        if (visible) {
            setTimeout(() => searchInput?.current?.select(), 100);
        }
    },
    render: (text) => <>{text}</>,
});
