import React from "react";
import { Button } from "antd";

function ModalButtons({ onCancel, editData }) {
    return (
        <div className="common_modal_buttons">
            <Button onClick={onCancel} danger type="text">
                İmtina
            </Button>
            <Button htmlType="submit" className="button_success">
                {editData ? "Yadda saxla" : "Əlavə et"}
            </Button>
        </div>
    );
}

export default ModalButtons;
