import React, { useEffect, useState } from "react";
import { Button, Form, Select } from "antd";
import { getAllData } from "../../helpers/helperFunctions";
import { urls } from "../../config/urls";
import { filterOption } from "../../utils/commonUtils";
import { httpClient } from "../../config/config";
import { SearchOutlined } from "@ant-design/icons";

function MainResourceSearch({ setTableData, setSelectedEmp, selectedStr, setSelectedStr }) {
    const [structure, setStructure] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldValue("empId", null);
        if (selectedStr) {
            httpClient({ url: urls.employee_position + "/structure/" + selectedStr }).then((res) =>
                setEmployees(res.data?.data)
            );
        } else {
            setEmployees([]);
        }
    }, [selectedStr]);

    useEffect(() => {
        getAllData(urls.structures_main_actives, setStructure);
    }, []);

    const handleSearch = (values) => {
        if (values.empId) {
            getAllData(urls.attachedEquipment + "/employee/" + values.empId, setTableData);
        } else {
            getAllData(urls.attachedEquipment + "/structure/" + selectedStr, setTableData);
        }
    };

    return (
        <Form layout="vertical" onFinish={handleSearch} form={form}>
            <div className="start_items gap_10">
                <Form.Item
                    label="Qurum"
                    name="strName"
                    rules={[{ required: true, message: "Struktur seçin" }]}
                >
                    <Select
                        style={{ width: "500px" }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        allowClear
                        onChange={(e) => setSelectedStr(e)}
                    >
                        {structure?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.fullName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Əməkdaş"
                    name="empId"
                    // rules={[{ required: true, message: "Əməkdaş seçin" }]}
                >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        style={{ width: "300px" }}
                        onChange={(e) => setSelectedEmp(e)}
                        allowClear
                    >
                        {employees?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {`${item.employeeSurname} ${item.employeeName} ${item.employeePatronymic}`}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Button icon={<SearchOutlined />} htmlType="submit" style={{ marginTop: "16px" }}>
                    Axtar
                </Button>
            </div>
        </Form>
    );
}

export default MainResourceSearch;
