import React, { useEffect, useState, useRef } from "react";
import { urls } from "../../config/urls";
import TableButton from "../../components/common/TableButton";
import PageHeader from "../../components/common/PageHeader";
import { Button, Input, Space, Table } from "antd";
import EmployeesModal from "./EmployeesModal";
import EmployeeSearch from "./EmployeeSearch";
import { httpClient } from "../../config/config";

// import { getAllData } from "../../helpers/helperFunctions";
// import { SearchOutlined } from "@ant-design/icons";

function Employees() {
    const [data, setData] = useState([]);
    // const [tableData, setTableData] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [editData, setEditData] = useState();
    const [requestBody, setRequestBody] = useState();

    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalSize, setTotalSize] = useState(0);

    const [computedData, setComputedData] = useState({
        recordCount: 0,
        salarySum: 0,
    });

    // Effect to calculate recordCount and salarySum based on tableData
    // useEffect(() => {
    //     const recordCount = data.employees?.length || 0;
    //     const salarySum =
    //         data.employees?.reduce((sum, item) => sum + parseFloat(item.salary), 0).toFixed(2) || 0;
    //     setComputedData({ recordCount, salarySum });
    // }, [data]);

    // useEffect(() => {
    //     setTableData(data);
    // }, [data]);

    useEffect(() => {
        getAll();
    }, []);

    const getAll = ({ size, page, request_body } = {}) => {
        const currentPage = page ? page - 1 : pageIndex;
        const currentSize = size || pageSize;
        const currentRequestBody = request_body || requestBody || {};

        if (page === 1) {
            setPageIndex(0);
        }

        const urlParams = new URLSearchParams({
            page: currentPage,
            size: currentSize,
            name: currentRequestBody.name || "",
            surname: currentRequestBody.surname || "",
            patronymic: currentRequestBody.patronymic || "",
            structureId: currentRequestBody.structureId || "",
        });

        httpClient({
            url: `${urls.employee_position_actives}?${urlParams}`,
        }).then((res) => {
            const { recordCount, salarySum } = res.data.data;
            setData(res.data.data);
            setComputedData({ recordCount, salarySum });
            setTotalSize(recordCount);
        });
    };
    const changePage = (pagination, filters, sorter, extra) => {
        const isPaginationChange = extra.action === "paginate";

        if (isPaginationChange) {
            setPageSize(pagination.pageSize);
            setPageIndex(pagination.current - 1);
            getAll({ size: pagination.pageSize, page: pagination.current });
        }
    };

    // const normalizeText = (text) => {
    //     return text.replace(/İ/g, "I").toLowerCase();
    // };
    // const searchInput = useRef(null);

    // const handleSearch = (selectedKeys, confirm, dataIndex) => {
    //     confirm();
    // };

    // const handleReset = (clearFilters) => {
    //     clearFilters();
    //     setTableData(data);
    // };
    // const getColumnSearchProps = (dataIndex) => ({
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    //         <div
    //             style={{
    //                 padding: 8,
    //             }}
    //             onKeyDown={(e) => e.stopPropagation()}
    //         >
    //             <Input
    //                 ref={searchInput}
    //                 // placeholder={`Search ${dataIndex}`}
    //                 value={selectedKeys[0]}
    //                 onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //                 style={{
    //                     marginBottom: 8,
    //                     display: "block",
    //                 }}
    //             />
    //             <Space>
    //                 <Button
    //                     type="primary"
    //                     onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //                     icon={<SearchOutlined />}
    //                     size="small"
    //                     style={{
    //                         width: 90,
    //                     }}
    //                 >
    //                     Axtar
    //                 </Button>
    //                 <Button
    //                     onClick={() => clearFilters && handleReset(clearFilters)}
    //                     size="small"
    //                     style={{
    //                         width: 90,
    //                     }}
    //                 >
    //                     Sıfırla
    //                 </Button>
    //                 <Button
    //                     type="link"
    //                     size="small"
    //                     onClick={() => {
    //                         close();
    //                     }}
    //                 >
    //                     Bağla
    //                 </Button>
    //             </Space>
    //         </div>
    //     ),
    //     filterIcon: (filtered) => (
    //         <SearchOutlined
    //             style={{
    //                 color: filtered ? "#1677ff" : undefined,
    //             }}
    //         />
    //     ),
    //     onFilter: (value, record) => {
    //         const recordValue = record[dataIndex];
    //         if (recordValue == null) {
    //             return false; // or handle as needed
    //         }
    //         return normalizeText(recordValue.toString()).includes(normalizeText(value));
    //     },
    //     onFilterDropdownOpenChange: (visible) => {
    //         if (visible) {
    //             setTimeout(() => searchInput.current?.select(), 100);
    //         }
    //     },
    //     render: (text) => <>{text}</>,
    // });

    const columns = [
        {
            title: "Adı",
            dataIndex: "employeeName",
            width: 200,
            // ...getColumnSearchProps("employeeName"),
            sorter: (a, b) => a?.employeeName?.localeCompare(b?.employeeName),
        },
        {
            title: "Soyadı",
            dataIndex: "employeeSurname",
            width: 200,
            // ...getColumnSearchProps("employeeSurname"),
            sorter: (a, b) => a?.employeeSurname?.localeCompare(b?.employeeSurname),
        },
        {
            title: "Ata adı",
            dataIndex: "employeePatronymic",
            width: 200,
            // ...getColumnSearchProps("employeePatronymic"),
            sorter: (a, b) => a?.employeePatronymic?.localeCompare(b?.employeePatronymic),
        },
        {
            title: "İş yeri",
            dataIndex: "strName",
            width: 400,
            // ...getColumnSearchProps("strName"),
            sorter: (a, b) => a?.strName?.localeCompare(b?.strName),
        },
        {
            title: "Vəzifəsi",
            dataIndex: "positionName",
            // ...getColumnSearchProps("positionName"),
            sorter: (a, b) => a?.positionName?.localeCompare(b?.positionName),
        },
        {
            title: "Əməliyyatlar",
            key: "action",
            width: 140,
            render: (info) => (
                <TableButton
                    {...{
                        getAll,
                        setEditData,
                        setIsVisible,
                        info,
                        id: info.id,
                        url: urls.employee_position,
                    }}
                />
            ),
        },
    ];

    return (
        <div>
            <PageHeader
                setIsVisible={setIsVisible}
                topText="Əməkdaşlar"
                bottomText="Əməkdaşların siyahısı"
            />

            <EmployeeSearch getAll={getAll} setRequestBody={setRequestBody} />
            <div className="start_items gap_20" style={{ marginTop: "20px" }}>
                <div>Əməkdaş sayı: {computedData.recordCount}</div>
                <div>Əmək haqqıların cəmi: {computedData.salarySum}</div>
            </div>
            <Table
                rowKey="id"
                locale={{ emptyText: "Məlumat tapılmadı" }}
                columns={columns}
                dataSource={data?.employees}
                pagination={{
                    defaultPageSize: pageSize,
                    pageSize: pageSize,
                    defaultCurrent: 1,
                    current: pageIndex == 0 ? 1 : pageIndex + 1,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50"],
                    total: totalSize,
                }}
                size="middle"
                onChange={changePage}
            />

            <EmployeesModal
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                editData={editData}
                setEditData={setEditData}
                getAll={getAll}
            />
        </div>
    );
}

export default Employees;
