import { Form, Input, Modal, message } from "antd";
import React from "react";
import ModalButtons from "../ModalButtons";
import { httpClient } from "../../../config/config";
import { urls } from "../../../config/urls";

function Password({ isVisible, setIsVisible }) {
    const [form] = Form.useForm();

    const onCancel = () => {
        setIsVisible(false);
        form.resetFields();
    };

    const onFinish = (values) => {
        const requestBody = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
        };

        httpClient({
            url: urls.users + "/reset",
            data: requestBody,
            method: "post",
            type: "auth",
        })
            .then(() => {
                message.success("Şifrə uğurla dəyişildi");
                onCancel();
            })
            .catch(() => {
                message.error("Köhnə şifrə düzgün deyil");
            });
    };

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
    const passwordValidationRule = {
        pattern: passwordPattern,
        message: "Şifrə ən azı 1 kiçik, 1 böyük hərf, 1 rəqəm və 6 simvoldan çox olmalıdır.",
    };

    return (
        <Modal
            title={"Şifrəni dəyiş"}
            open={isVisible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose={true}
            maskClosable={false}
        >
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Form.Item name="oldPassword" label="Köhnə şifrə" rules={[passwordValidationRule]}>
                    <Input autoComplete="new-password" />
                </Form.Item>
                <Form.Item name="newPassword" label="Yeni şifrə" rules={[passwordValidationRule]}>
                    <Input.Password autoComplete="new-password" />
                </Form.Item>
                <ModalButtons onCancel={onCancel} editData={true} />
            </Form>
        </Modal>
    );
}

export default Password;
