import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { httpClient } from "../../config/config";
import { urls } from "../../config/urls";
import { dateForForm, dateForRequest, getAllData } from "../../helpers/helperFunctions";
import CustomDatePicker from "../../components/common/CustomComponents/CustomDatePicker";
import UploadImage from "./UploadImage";
import { filterOption } from "../../utils/commonUtils";
import EmployeePdfModal from "./EmployeePdfModal";

function EmployeesModal({ isVisible, setIsVisible, getAll, editData, setEditData }) {
    const [staff, setStaff] = useState([]);
    const [structure, setStructure] = useState([]);
    const [form] = Form.useForm();
    const [pdfVisible, setPdfVisible] = useState(false);
    useEffect(() => {
        getAllData(urls.structures_actives, setStructure);
    }, []);

    useEffect(() => {
        if (editData) {
            httpClient({ url: urls.employees + "/" + editData.employeeId }).then((res) => {
                form.setFieldsValue({
                    ssn: res.data.data.ssn,
                    finKod: res.data.data.finKod,
                    birthday: dateForForm(res.data.data.birthday),
                });
            });
            getStaff(editData.strId).then(() => {
                form.setFieldsValue({
                    name: editData.employeeName,
                    surname: editData.employeeSurname,
                    patronymic: editData.employeePatronymic,
                    structure: editData.strId,
                    staff: editData.staffId,
                    contractNumber: editData.contractNumber,
                    contractDate: dateForForm(editData.contractDate),
                    contractStartDate: dateForForm(editData.contractStartDate),
                    contractEndDate: dateForForm(editData.contractEndDate),
                    workStartDate: dateForForm(editData.workStartDate),
                    salary: editData.salary || 0,
                });
            });
        }
    }, [editData]);

    const getStaff = (id) => getAllData(urls.staffUnits + "/empty-structures/" + id, setStaff);

    const onFinish = (values) => {
        let empData = {
            name: values.name,
            surname: values.surname,
            patronymic: values.patronymic,
            finKod: values.finKod,
            ssn: values.ssn,
            birthday: dateForRequest(values.birthday),
        };
        if (editData) {
            let posData = {
                employeeId: editData.employeeId,
                staffId: values.staff,
                contractNumber: values.contractNumber,
                contractDate: dateForRequest(values.contractDate),
                contractStartDate: dateForRequest(values.contractStartDate),
                contractEndDate: dateForRequest(values.contractEndDate),
                workStartDate: dateForRequest(values.workStartDate),
                salary: values.salary || 0,
            };

            const updateEmp = httpClient({
                url: urls.employees + "/" + editData.employeeId,
                method: "put",
                data: empData,
            });
            const updateEmpPos = httpClient({
                url: urls.employee_position + "/" + editData?.id,
                method: "put",
                data: posData,
            });
            Promise.all([updateEmp, updateEmpPos]).then(() => {
                getAll();
            });
        } else {
            httpClient({ url: urls.employees, method: "post", data: empData }).then((res) => {
                let posData = {
                    employeeId: res.data?.data?.body?.data?.id,
                    staffId: values.staff,
                    contractNumber: values.contractNumber,
                    contractDate: dateForRequest(values.contractDate),
                    contractStartDate: dateForRequest(values.contractStartDate),
                    contractEndDate: dateForRequest(values.contractEndDate),
                    workStartDate: dateForRequest(values.workStartDate),
                    salary: values.salary || 0,
                };
                httpClient({ url: urls.employee_position, method: "post", data: posData }).then(
                    (posRes) => {
                        const empData = res.data?.data?.body?.data;
                        const posData = posRes.data?.data?.body?.data;

                        setEditData({
                            employeeId: empData.id,
                            employeeName: empData.name,
                            employeeSurname: empData.surname,
                            employeePatronymic: empData.patronymic,
                            positionId: posData.id,
                            // "positionName": "Mühasib",
                            // "strName": "\"APF GROUP\" MƏHDUD MƏSULİYYƏTLİ CƏMİYYƏTİ",
                            strId: values.structure,
                            mainStrId: null,
                            id: empData.id,
                            staffId: values.staff,
                            contractNumber: posData.contractNumber,
                            contractDate: posData.contractDate,
                            contractStartDate: posData.contractStartDate,
                            contractEndDate: posData.contractEndDate,
                            workStartDate: posData.workStartDate,
                            salary: posData.salary,
                        });
                        getAll({ page: 1 });
                    }
                );
            });
        }
    };

    const onCancel = () => {
        setIsVisible(false);
        setEditData();
        setStaff([]);
        form.resetFields();
    };

    return (
        <Modal
            title={"Əməkdaşlar"}
            open={isVisible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose={true}
            maskClosable={false}
            width={1000}
        >
            <div>
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Row gutter={20} style={{ padding: "10px" }}>
                        <Col span={16}>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Ad"
                                        name="name"
                                        rules={[{ required: true, message: "Adı daxil edin" }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Ata adı"
                                        name="patronymic"
                                        rules={[
                                            { required: true, message: "Ata adını daxil edin" },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Fin Kod"
                                        name="finKod"
                                        // rules={[{ required: true, message: "Fin kodu daxil edin" }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Soyad"
                                        name="surname"
                                        rules={[{ required: true, message: "Soyadı daxil edin" }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Doğum tarixi"
                                        name="birthday"
                                        // rules={[{ required: true, message: "Doğum tarixini daxil edin" }]}
                                    >
                                        <CustomDatePicker />
                                    </Form.Item>
                                    <Form.Item
                                        label="SSN"
                                        name="ssn"
                                        // rules={[{ required: true, message: "SSN daxil edin" }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Struktur"
                                        name="structure"
                                        rules={[{ required: true, message: "Struktur seçin" }]}
                                    >
                                        <Select
                                            onChange={(e) => getStaff(e)}
                                            style={{ width: "100%" }}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                        >
                                            {structure?.map((item) => (
                                                <Select.Option key={item.id} value={item.id}>
                                                    {item.fullName}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Ştat"
                                        name="staff"
                                        rules={[{ required: true, message: "Ştat seçin" }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                        >
                                            {staff?.map((item) => (
                                                <Select.Option key={item.id} value={item.id}>
                                                    {item.positionName}
                                                </Select.Option>
                                            ))}

                                            {editData && (
                                                <Select.Option
                                                    key={editData.staffId}
                                                    value={editData.staffId}
                                                >
                                                    {editData.positionName}
                                                </Select.Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Müqavilə nömrəsi"
                                        name="contractNumber"
                                        // rules={[{ required: true, message: "Müqavilə nömrəsini daxil edin" }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Müqavilə tarixi"
                                        name="contractDate"
                                        // rules={[{ required: true, message: "Müqavilə tarixini daxil edin" }]}
                                    >
                                        <CustomDatePicker />
                                    </Form.Item>
                                    <Form.Item
                                        label="Müqavilənin başlama tarixini"
                                        name="contractStartDate"
                                        // rules={[{ required: true, message: "Müqavilənin başlama tarixini daxil edin" }]}
                                    >
                                        <CustomDatePicker />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Əmək haqqı"
                                        name="salary"
                                        // rules={[{ required: true, message: "SSN daxil edin" }]}
                                    >
                                        <InputNumber min={0} style={{ width: "100%" }} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Müqavilənin bitmə tarixi"
                                        name="contractEndDate"
                                        // rules={[{ required: true, message: "Doğum tarixini daxil edin" }]}
                                    >
                                        <CustomDatePicker />
                                    </Form.Item>
                                    <Form.Item
                                        label="İşə başlama tarixi"
                                        name="workStartDate"
                                        // rules={[{ required: true, message: "Doğum tarixini daxil edin" }]}
                                    >
                                        <CustomDatePicker />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <UploadImage editData={editData} />
                        </Col>
                    </Row>

                    <div className="center_items gap_20">
                        <Button
                            onClick={() => {
                                setPdfVisible(true);
                            }}
                            type="primary"
                        >
                            Pdf çapı
                        </Button>
                        <Button className="button_success" htmlType="submit">
                            Yadda saxla
                        </Button>
                    </div>
                </Form>
            </div>
            <EmployeePdfModal
                pdfVisible={pdfVisible}
                setPdfVisible={setPdfVisible}
                editData={editData}
            />
        </Modal>
    );
}

export default EmployeesModal;
