export const DictionaryView = "DictionaryView";
export const DemandStatusCompanyAccountDep = "DemandStatusCompanyAccountDep";
export const DemandStatusLeadAccept = "DemandStatusLeadAccept";
export const ContragentListApprove = "ContragentListApprove";
export const DemandStatusSupplyDep = "DemandStatusSupplyDep";
export const DemandStatusLowDep = "DemandStatusLowDep";
export const DemandStatusDocViewer = "DemandStatusDocViewer";
export const DemandStatusAccountDep = "DemandStatusAccountDep";
export const DemandStatusNewDoc = "DemandStatusNewDoc";
export const DemandStatusCompanyLead = "DemandStatusCompanyLead";
export const DemandView = "DemandView";
export const EmployeeView = "EmployeeView";
export const UserSettings = "UserSettings";
export const AttachmentView = "AttachmentView";
export const AttachmentAllView = "AttachmentAllView";
export const AttachmentAdd = "AttachmentAdd";
export const AttachmentDelete = "AttachmentDelete";
export const AttachmentUpdate = "AttachmentUpdate";
export const AttachmentApprove = "AttachmentApprove";
export const ContragentAddEdit = "ContragentAddEdit";
export const StructureView = "StructureView";
export const StructureEdit = "StructureEdit";
export const StaffUnitView = "StaffUnitView";
export const StaffUnitEdit = "StaffUnitEdit";
export const ProductListAddEdit = "ProductListAddEdit";

//  === ESD ROLES ===
export const DocumentTypeView = "DocumentTypeView";
export const DocumentTypeGetAll = "DocumentTypeGetAll";
export const DocumentTypeDelete = "DocumentTypeDelete";
export const DocumentTypeAdd = "DocumentTypeAdd";

export const DocumentKindView = "DocumentKindView";
export const DocumentKindGetAll = "DocumentKindGetAll";
export const DocumentKindDelete = "DocumentKindDelete";
export const DocumentKindAdd = "DocumentKindAdd";

export const DeliveryFormView = "DeliveryFormView";
export const DeliveryFormGetAll = "DeliveryFormGetAll";
export const DeliveryFormDelete = "DeliveryFormDelete";
export const DeliveryFormAdd = "DeliveryFormAdd";

export const ExternalStructureView = "ExternalStructureView";
export const ExternalStructureGetAll = "ExternalStructureGetAll";
export const ExternalStructureDelete = "ExternalStructureDelete";
export const ExternalStructureAdd = "ExternalStructureAdd";

export const ExternalPersonView = "ExternalPersonView";
export const ExternalPersonGetAll = "ExternalPersonGetAll";
export const ExternalPersonDelete = "ExternalPersonDelete";
export const ExternalPersonAdd = "ExternalPersonAdd";
export const PositionApprove = "PositionApprove";
export const PositionApproveView = "PositionApproveView";

export const DerkenarTemplateDelete = "DerkenarTemplateDelete";
export const DerkenarTemplateView = "DerkenarTemplateView";
export const DerkenarTemplateAdd = "DerkenarTemplateAdd";
export const DerkenarTemplateGetAll = "DerkenarTemplateGetAll";

export const NomenclatureDelete = "NomenclatureDelete";
export const NomenclatureView = "NomenclatureView";
export const NomenclatureAdd = "NomenclatureAdd";
export const NomenclatureGetAll = "NomenclatureGetAll";

export const DocumentView = "DocumentView";
export const DocumentAdd = "DocumentAdd";
export const DocumentGetAll = "DocumentGetAll";

export const DerkenarSendPermissionView = "DerkenarSendPermissionView";
export const DerkenarSendPermissionGetAll = "DerkenarSendPermissionGetAll";
export const DerkenarSendPermissionAdd = "DerkenarSendPermissionAdd";

export const CurationGetAll = "CurationGetAll";
export const CurationView = "CurationView";
export const CurationAdd = "CurationAdd";

export const roleMenuRouteConfig = {
    [UserSettings]: ["/users"],
    [DictionaryView]: ["/settings"],
    [DemandView]: ["/demand-order"],
    [EmployeeView]: ["/employees"],
    [StructureView]: ["/structure"],
    [StaffUnitView]: ["/staff-unit"],
    [AttachmentView]: ["/main-resource"],
    [PositionApproveView]: ["/position-approve"],
    [DocumentKindView]: ["document-kind"],
    [DocumentTypeView]: ["document-type"],
    [DeliveryFormView]: ["delivery-form"],
    [ExternalStructureView]: ["external-structure"],
    [ExternalPersonView]: ["external-person"],
    [DerkenarTemplateView]: ["derkenar-template"],
    [NomenclatureView]: ["nomenklature"],
    [DocumentView]: ["document"],
    [DerkenarSendPermissionView]: ["derkenar-permission"],
    [CurationView]: ["curation"],
};
