export const DocsIcon = (props) => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="512.000000pt"
        height="512.000000pt"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
        {...props}
    >
        <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="#000000"
            strokeWidth="100"
        >
            <path
                d="M1190 4991 c-152 -49 -253 -142 -312 -290 -23 -55 -23 -62 -28 -645
l-5 -588 -64 -20 c-54 -17 -75 -30 -127 -82 -52 -53 -65 -73 -83 -130 -21 -67
-21 -84 -21 -835 0 -646 2 -776 15 -824 26 -102 89 -177 185 -221 30 -14 63
-26 73 -26 16 0 17 -24 17 -367 0 -203 5 -399 10 -438 20 -139 109 -274 226
-345 121 -73 41 -69 1476 -69 901 -1 1303 2 1340 10 126 26 260 124 320 236
55 100 58 131 58 570 l0 403 23 0 c37 0 140 55 179 96 20 22 50 66 65 99 l28
60 0 805 c0 936 7 870 -100 977 -51 51 -72 64 -126 81 l-64 20 -3 245 -2 245
-461 493 c-253 272 -476 509 -495 527 l-34 32 -1017 -1 c-948 0 -1022 -1
-1073 -18z m1972 -627 l3 -486 24 -19 c22 -18 45 -19 472 -19 l449 0 0 -185 0
-185 -1555 0 -1556 0 3 563 c4 631 2 613 79 701 47 54 97 86 159 102 34 10
275 13 982 13 l937 1 3 -486z m518 -19 c145 -154 277 -295 294 -312 l30 -33
-342 0 -342 0 0 362 0 363 49 -50 c26 -28 167 -176 311 -330z m642 -1063 c20
-13 47 -40 60 -60 l23 -37 0 -790 0 -790 -23 -37 c-13 -20 -38 -46 -56 -58
l-34 -20 -1732 0 -1732 0 -34 20 c-18 12 -43 38 -56 58 l-23 37 -3 768 c-2
524 1 781 8 808 6 22 23 53 38 70 56 62 -55 58 1817 56 l1710 -2 37 -23z
m-202 -2325 c0 -373 -6 -450 -40 -517 -26 -51 -93 -115 -148 -142 l-47 -23
-1325 0 -1325 0 -50 27 c-60 32 -114 87 -146 148 -24 45 -24 46 -27 458 l-3
412 1556 0 1555 0 0 -363z"
            />
            <path
                d="M2413 2896 c-172 -40 -292 -158 -337 -333 -21 -79 -21 -250 -1 -328
58 -225 215 -346 455 -348 160 -2 266 36 356 129 100 102 152 262 140 430 -15
217 -118 367 -295 432 -83 30 -227 38 -318 18z m216 -162 c79 -23 143 -91 170
-179 28 -91 28 -219 0 -310 -24 -80 -81 -148 -149 -178 -54 -24 -159 -27 -211
-5 -113 48 -179 173 -179 338 0 256 157 397 369 334z"
            />
            <path
                d="M3501 2884 c-80 -21 -145 -60 -206 -125 -97 -102 -141 -238 -132
-409 14 -244 141 -412 344 -455 173 -37 323 -4 427 95 63 60 106 146 108 216
0 40 -4 52 -26 71 -36 30 -86 31 -112 1 -11 -13 -35 -54 -53 -91 -38 -79 -85
-119 -162 -137 -93 -23 -191 8 -248 77 -52 62 -74 153 -69 286 3 97 8 120 32
175 48 104 119 152 226 152 105 0 161 -35 233 -148 41 -65 73 -87 111 -76 118
36 70 215 -84 314 -104 67 -261 89 -389 54z"
            />
            <path
                d="M1110 2862 l-30 -30 0 -435 c0 -489 -3 -469 75 -487 52 -12 288 -12
393 0 235 27 356 178 369 460 9 191 -29 303 -141 416 -92 92 -112 97 -398 102
l-238 4 -30 -30z m470 -156 c93 -48 130 -124 138 -285 6 -126 -7 -189 -54
-264 -52 -84 -87 -97 -252 -97 l-132 0 0 336 0 336 133 -4 c102 -3 140 -8 167
-22z"
            />
        </g>
    </svg>
);

export const DocSettingsIcon = (props) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="24px" height="24px" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#fff" stroke="none">
<path d="M895 5108 c-27 -5 -87 -27 -133 -48 -108 -51 -222 -161 -270 -260
-66 -137 -62 24 -62 -2240 0 -2264 -4 -2103 62 -2240 48 -99 162 -209 269
-259 46 -22 111 -44 143 -50 41 -8 553 -11 1660 -11 1770 0 1670 -3 1807 66
97 48 211 162 257 254 66 136 62 -26 62 2240 0 2265 4 2104 -62 2240 -45 91
-169 213 -262 258 -133 65 -42 62 -1811 61 -972 -1 -1630 -5 -1660 -11z m3297
-324 c63 -21 126 -78 154 -138 l24 -51 0 -2035 0 -2035 -24 -51 c-13 -27 -43
-67 -67 -87 -83 -71 30 -67 -1724 -67 -1757 0 -1631 -5 -1715 67 -24 21 -53
60 -66 87 l-24 51 0 2035 0 2035 24 51 c27 58 91 117 150 138 35 12 264 15
1632 15 1414 1 1596 -1 1636 -15z"/>
<path d="M1143 2109 c-18 -11 -41 -35 -53 -53 -19 -32 -20 -48 -20 -563 l0
-530 23 -34 c12 -19 42 -44 67 -57 44 -23 51 -23 255 -19 189 3 216 6 267 26
80 31 159 106 200 189 l33 67 0 360 0 360 -28 56 c-41 84 -98 143 -175 181
l-66 33 -236 3 c-224 2 -237 1 -267 -19z m441 -315 c14 -13 16 -55 16 -300 0
-343 7 -324 -125 -324 l-85 0 0 320 0 320 89 0 c68 0 93 -4 105 -16z"/>
<path d="M2383 2112 c-125 -45 -216 -152 -243 -287 -8 -41 -11 -161 -8 -371
l3 -310 37 -76 c45 -89 94 -138 187 -182 64 -30 70 -31 201 -31 131 0 137 1
201 31 93 44 142 93 187 182 l37 76 3 310 c4 334 -2 395 -43 477 -33 64 -104
132 -173 166 -53 26 -66 28 -197 31 -115 2 -149 -1 -192 -16z m272 -326 c14
-21 15 -63 13 -304 -2 -214 -6 -284 -16 -294 -18 -18 -166 -18 -184 0 -10 10
-14 80 -16 294 -4 343 -9 328 108 328 73 0 81 -2 95 -24z"/>
<path d="M3438 2106 c-101 -41 -173 -114 -215 -217 -16 -40 -18 -80 -18 -399
l0 -355 33 -67 c41 -83 120 -158 200 -189 51 -20 78 -23 267 -26 204 -4 211
-4 255 19 66 34 93 79 88 149 -5 65 -32 109 -82 133 -28 13 -69 16 -220 16
-173 0 -187 1 -206 20 -19 19 -20 33 -20 304 0 245 2 287 16 300 13 13 45 16
198 16 169 0 185 2 226 23 66 33 93 78 88 148 -5 65 -32 109 -82 133 -28 13
-71 16 -253 16 -205 0 -221 -2 -275 -24z"/>
</g>
</svg>


);
