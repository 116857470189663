import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRoutes = () => {
    const isLoggedIn = Cookies.get("apf_access_token");
    useEffect(() => {
        if (!isLoggedIn) window.location.replace("/login");
    }, []);
    return <>{isLoggedIn ? <Outlet /> : <></>}</>;
};

export default ProtectedRoutes;
