import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
    name: "loader",
    initialState: {
        isLoading: false,
        isRoleLoading: true,
    },
    reducers: {
        loading: (state) => {
            state.isLoading = true;
        },
        ready: (state) => {
            state.isLoading = false;
        },
        roleLoading: (state) => {
            state.isRoleLoading = true;
        },
        roleReady: (state) => {
            state.isRoleLoading = false;
        },
    },
});

export const { loading, ready, roleReady, roleLoading } = loaderSlice.actions;
export default loaderSlice.reducer;
