import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        isLoggedIn: false,
        roles: [],
        userInfo: null,
        userPhoto: null,
    },
    reducers: {
        login: (state, action) => {
            state.isLoggedIn = true;
            state.roles = action.payload.roles;
            state.userInfo = action.payload.userInfo;
            state.userPhoto = action.payload.userPhoto;
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.roles = [];
        },
    },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
