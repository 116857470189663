import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import { filterOption } from "../../utils/commonUtils";
import { getAllData } from "../../helpers/helperFunctions";
import { urls } from "../../config/urls";

function EmployeeSearch({  getAll, setRequestBody }) {
    const [structure, setStructure] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        getAllData(urls.structures_main_actives, setStructure);
    }, []);

    // function toLowerAzerbaijani(str) {
    //     const azerbaijaniMap = {
    //         İ: "i",
    //         I: "ı",
    //         // Add other specific mappings if needed
    //     };

    //     return str
    //         .split("")
    //         .map((char) => azerbaijaniMap[char] || char.toLowerCase())
    //         .join("");
    // }

    // function filterEmployees(employees, criteria) {
    //     return employees.filter((employee) => {
    //         return (
    //             (criteria.employeeName
    //                 ? toLowerAzerbaijani(employee.employeeName).includes(
    //                       toLowerAzerbaijani(criteria.employeeName)
    //                   )
    //                 : true) &&
    //             (criteria.employeePatronymic
    //                 ? toLowerAzerbaijani(employee.employeePatronymic).includes(
    //                       toLowerAzerbaijani(criteria.employeePatronymic)
    //                   )
    //                 : true) &&
    //             (criteria.employeeSurname
    //                 ? toLowerAzerbaijani(employee.employeeSurname).includes(
    //                       toLowerAzerbaijani(criteria.employeeSurname)
    //                   )
    //                 : true) &&
    //             (criteria.strName ? employee.mainStrId == criteria.strName : true)
    //         );
    //     });
    // }

    const handleSearch = (values) => {
        let criteria = {
            name: values.employeeName,
            patronymic: values.employeePatronymic,
            surname: values.employeeSurname,
            structureId: values.structureId,
        };
        setRequestBody(criteria);
        getAll({ page: 1, request_body: criteria });

        // const filteredEmployees = filterEmployees(tableData?.employees, criteria);

        // const recordCount = filteredEmployees.length;
        // let salarySum = 0;
        // filteredEmployees.map((item) => (salarySum += item.salary));
        // let newObj = {
        //     ...tableData,
        //     employees: filteredEmployees,
        // };

        // setTableData(newObj);
    };

    const removeSearch = () => {
        form.resetFields();
        setRequestBody();
    };

    return (
        <Form layout="vertical" onFinish={handleSearch} form={form}>
            <div className="start_items gap_10">
                <Form.Item label="Adı" name="employeeName">
                    <Input />
                </Form.Item>
                <Form.Item label="Soyadı" name="employeeSurname">
                    <Input />
                </Form.Item>
                <Form.Item label="Ata adı" name="employeePatronymic">
                    <Input />
                </Form.Item>
                <Form.Item label="Qurum" name="structureId">
                    <Select
                        style={{ width: "500px" }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        allowClear
                    >
                        {structure?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.fullName}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Button icon={<SearchOutlined />} htmlType="submit" style={{ marginTop: "16px" }}>
                    Axtar
                </Button>
                <Button style={{ marginTop: "16px" }} onClick={removeSearch}>
                    Sıfırla
                </Button>
            </div>
        </Form>
    );
}

export default EmployeeSearch;
