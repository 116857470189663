import dayjs from "dayjs";
import { httpClient } from "../config/config";

export const getAllData = async (url, setData, type) => {
    return httpClient({ url, method: "get", type })
        .then((res) => {
            type === "auth" ? setData(res?.data) : setData(res?.data?.data);
        })
        .catch((error) => {
            console.log(error.res);
        });
};

export const dateForRequest = (value) => (value ? dayjs(value).format("DD.MM.YYYY") : null);
export const dateForForm = (value) => (value ? dayjs(value, "DD.MM.YYYY") : null);
