import { Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../config/config";
import { urls } from "../../config/urls";
import ModalButtons from "../../components/common/ModalButtons";
import { getAllData } from "../../helpers/helperFunctions";
import { filterOption } from "../../utils/commonUtils";
import SelectEquipment from "./SelectEquipment";

function MainResourceModal({ isVisible, setIsVisible, getAll, editData, setEditData }) {
    const [form] = Form.useForm();
    const [structure, setStructure] = useState([]);
    const [selectedStr, setSelectedStr] = useState();
    const [employees, setEmployees] = useState([]);
    const [locations, setLocations] = useState([]);
    const [conditions, setConditions] = useState([]);

    const [selectedEqpm, setSelectedEqpm] = useState();
    const [isSelectVisible, setIsSelectVisible] = useState(false);

    useEffect(() => {
        form.setFieldValue("employeeId", null);
        if (selectedStr) {
            httpClient({ url: urls.employee_position + "/structure/" + selectedStr }).then((res) =>
                setEmployees(res.data?.data)
            );
        } else {
            setEmployees([]);
        }
    }, [selectedStr]);

    useEffect(() => {
        getAllData(urls.structures_actives, setStructure);
        getAllData(urls.equipmentLocation_actives, setLocations);
        getAllData(urls.equipmentCondition_actives, setConditions);
    }, []);

    useEffect(() => {
        if (editData) {
            form.setFieldsValue({
                structureId: editData.structureId,
                employeeId: editData.employeeId,
                equipmentId: editData.equipmentName,
                attachedCount: editData.attachedCount,
                locationId: editData.equipmentLocationId,
                conditionId: editData.equipmentConditionId,
            });
            setSelectedEqpm({ id: editData.equipmentId, name: editData.equipmentName });
        }
    }, [editData]);

    const onFinish = (values) => {
        let data = {
            structureId: values.structureId,
            employeeId: values.employeeId,
            equipmentId: selectedEqpm?.id,
            attachedCount: values.attachedCount,
            locationId: values.locationId,
            conditionId: values.conditionId,
        };

        if (editData) {
            httpClient({
                url: urls.attachedEquipment + "/" + editData.id,
                method: "put",
                data,
            }).then(() => {
                getAll();
                onCancel();
            });
        } else {
            httpClient({ url: urls.attachedEquipment, method: "post", data }).then(() => {
                getAll();
                onCancel();
            });
        }
    };

    const onCancel = () => {
        setIsVisible(false);
        setEditData();
        form.resetFields();
    };

    useEffect(() => {
        if (selectedEqpm) form.setFieldValue("equipmentId", selectedEqpm.name);
    }, [selectedEqpm]);
    return (
        <Modal
            title={"Vəsaitlər"}
            open={isVisible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose={true}
            maskClosable={false}
            width={800}
        >
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col span={14}>
                        <Form.Item
                            label="Struktur"
                            name="structureId"
                            rules={[{ required: true, message: "Struktur seçin" }]}
                        >
                            <Select
                                style={{ width: "100%" }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={(e) => setSelectedStr(e)}
                            >
                                {structure?.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.fullName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label="Əməkdaş"
                            name="employeeId"
                            // rules={[{ required: true, message: "Əməkdaş seçin" }]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                allowClear
                            >
                                {employees?.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {`${item.employeeSurname} ${item.employeeName} ${item.employeePatronymic}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            label="Adı"
                            name="equipmentId"
                            rules={[{ required: true, message: "Adı daxil edin" }]}
                        >
                            <Input
                                readOnly
                                onClick={() => {
                                    setIsSelectVisible(true);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label="Sayı"
                            name="attachedCount"
                            rules={[{ required: true, message: "Sayı daxil edin" }]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={14}>
                        <Form.Item
                            label="Yerləşmə yeri"
                            name="locationId"
                            // rules={[{ required: true, message: "Əməkdaş seçin" }]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                allowClear
                            >
                                {locations?.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label="Vəziyyəti"
                            name="conditionId"
                            // rules={[{ required: true, message: "Əməkdaş seçin" }]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                allowClear
                            >
                                {conditions?.map((item) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <ModalButtons onCancel={onCancel} editData={editData} />
            </Form>
            <SelectEquipment
                isVisible={isSelectVisible}
                setIsVisible={setIsSelectVisible}
                setSelectedEqpm={setSelectedEqpm}
            />
        </Modal>
    );
}

export default MainResourceModal;
