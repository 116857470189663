import React from "react";
import styles from "./header.module.css";
import { useSelector } from "react-redux";
import Avatar from "../../../assets/images/avatar.png";

const Header = () => {
    const userInfo = useSelector((state) => state.auth.userInfo);
    const userPhoto = useSelector((state) => state.auth.userPhoto);

    return (
        <div className={styles.header}>
            <div className={styles.userInfo}>
                <div className={styles.info}>
                    {userInfo
                        ? `${userInfo?.surname} ${userInfo?.name} ${userInfo?.fatherName}`
                        : ""}
                </div>
                <div className={styles.userPhoto}>
                    <img src={userPhoto ? "data:image/png;base64, " + userPhoto : Avatar} />
                </div>
            </div>
        </div>
    );
};

export default Header;
