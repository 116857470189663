import React from "react";
import { Button, Form, Input, Radio } from "antd";
import { SearchOutlined } from "@ant-design/icons";

function EquipmentSearch({ setSearchText, setSearchCase, removeSearch }) {
    const [form] = Form.useForm();

    const handleSearch = () => {
        setSearchCase(true);
    };

    return (
        <Form layout="vertical" onFinish={handleSearch} form={form}>
            <div className="start_items gap_10">
                <Form.Item name="name" rules={[{ required: true, message: "Boş olmaz" }]}>
                    <Input
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder={"Adı daxil edin"}
                    />
                </Form.Item>
                <Button
                    icon={<SearchOutlined />}
                    htmlType="submit"
                    style={{ marginBottom: "10px" }}
                >
                    Axtar
                </Button>
                <Button style={{ marginBottom: "10px" }} onClick={() => removeSearch(form)}>
                    Sıfırla
                </Button>
            </div>
        </Form>
    );
}

export default EquipmentSearch;
