import { Modal } from "antd";
import React from "react";

function EmpNewData({ isVisible, setIsVisible, data }) {
    return (
        <Modal
            title={"Dəyişikliklər"}
            open={isVisible}
            footer={null}
            onCancel={() => setIsVisible(false)}
            destroyOnClose={true}
            maskClosable={false}
            centered
        >
            <div>
                {data?.contractDate != data?.activePosition?.contractDate && (
                    <div>
                        Əvvəlki qeydiyyat tarixi: {data.activePosition?.contractDate} {" - "}
                        <b>Yeni qeydiyyat tarixi: {data.contractDate}</b>
                    </div>
                )}
                {data?.contractEndDate != data?.activePosition?.contractEndDate && (
                    <div>
                        Əvvəlki müqavilə bitmə tarixi: {data.activePosition?.contractEndDate}{" "}
                        {" - "}
                        <b>Yeni müqavilə bitmə tarixi: {data.contractEndDate}</b>
                    </div>
                )}
                {data?.contractNumber != data?.activePosition?.contractNumber && (
                    <div>
                        Əvvəlki müqavilə nömrəsi: {data.activePosition?.contractNumber} {" - "}
                        <b>Yeni müqavilə nömrəsi: {data.contractNumber}</b>
                    </div>
                )}
                {data?.contractStartDate != data?.activePosition?.contractStartDate && (
                    <div>
                        Əvvəlki müqavilə başlama tarixi: {data.activePosition?.contractStartDate}{" "}
                        {" - "}
                        <b>Yeni müqavilə başlama tarixi: {data.contractStartDate}</b>
                    </div>
                )}
                {data?.positionName != data?.activePosition?.positionName && (
                    <div>
                        Əvvəlki vəzifəsi: {data.activePosition?.positionName} {" - "}
                        <b>Yeni vəzifəsi: {data.positionName}</b>
                    </div>
                )}
                {data?.salary != data?.activePosition?.salary && (
                    <div>
                        Əvvəlki əmək haqqı: {data.activePosition?.salary} {" - "}
                        <b>Yeni əmək haqqı: {data.salary}</b>
                    </div>
                )}
                {data?.strName != data?.activePosition?.strName && (
                    <div>
                        Əvvəlki iş yeri: {data.activePosition?.strName} {" - "}
                        <b>Yeni iş yeri: {data.strName}</b>
                    </div>
                )}
                {data?.workStartDate != data?.activePosition?.workStartDate && (
                    <div>
                        Əvvəlki işə başlama tarixi: {data.activePosition?.workStartDate} {" - "}
                        <b>Yeni işə başlama tarixi: {data.workStartDate}</b>
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default EmpNewData;
