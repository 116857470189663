import React from "react";
import { Button } from "antd";

function PageHeader({ setIsVisible, topText, bottomText, roles, hideAddButton }) {
    return (
        <div className="common_page_head">
            <div className="common_page_text">
                <h2>{topText}</h2>
                <span>{bottomText}</span>
            </div>

            {!hideAddButton && (
                <div className="common_page_button" style={{ display: "flex" }}>
                    <Button
                        className="button_success"
                        onClick={() => {
                            setIsVisible(true);
                        }}
                        disabled={roles || false}
                    >
                        + Əlavə et
                    </Button>
                </div>
            )}
        </div>
    );
}

export default PageHeader;
