import { Button, Input, Popconfirm, Radio, Space, Table, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { dateForForm, dateForRequest, getAllData } from "../../helpers/helperFunctions";
import { CheckCircle, FileEditIcon, MailCheck, Trash2 } from "lucide-react";
import { httpClient } from "../../config/config";
import { urls } from "../../config/urls";
import TableButton from "../../components/common/TableButton";
import PageHeader from "../../components/common/PageHeader";
import MainResourceSearch from "./MainResourceSearch";
import MainResourceModal from "./MainResourceModal";
import { deleteHandler } from "../../utils/deleteUtils";
import { useSelector } from "react-redux";
import {
    AttachmentAdd,
    AttachmentApprove,
    AttachmentDelete,
    AttachmentUpdate,
} from "../auth/roles";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

function MainResource() {
    const [data, setData] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [editData, setEditData] = useState();
    const [selectedEmp, setSelectedEmp] = useState();
    const [selectedStr, setSelectedStr] = useState();
    const roles = useSelector((state) => state.auth.roles);

    const handleApprove = (id) => {
        httpClient({ url: urls.attachedEquipment + "/approve/" + id, method: "post" }).then(() => {
            message.success("Təsdiq olundu");
            getAll();
        });
    };
    const getAll = () => getAllData(urls.attachedEquipment + "/employee/" + selectedEmp, setData);
    const normalizeText = (text) => {
        return text.replace(/İ/g, "I").toLowerCase();
    };
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters) => {
        clearFilters();
    };
    const getColumnSearchProps = (dataIndex) => {
        if (dataIndex == "approvedBy") {
            return {
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Radio.Group
                            onChange={(e) =>
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                            }
                            value={selectedKeys[0]}
                        >
                            <Radio.Button value="all">Hamısı</Radio.Button>
                            <Radio.Button value="bəli">Bəli</Radio.Button>
                            <Radio.Button value="xeyr">Xeyr</Radio.Button>
                        </Radio.Group>
                        <Button
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            type="primary"
                            size="small"
                            style={{ width: 90, margin: "10px 0" }}
                        >
                            Axtar
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Sıfırla
                        </Button>
                    </div>
                ),
                filterIcon: (filtered) => (
                    <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
                ),
                onFilter: (value, record) => {
                    if (value === "all") return true;
                    return value === "bəli" ? record[dataIndex] : !record[dataIndex];
                },
                onFilterDropdownOpenChange: (visible) => {
                    if (visible) {
                        setTimeout(() => searchInput.current?.select(), 100);
                    }
                },
                render: (text) => <>{text ? "Bəli" : "Xeyr"}</>,
            };
        } else {
            return {
                filterDropdown: ({
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    close,
                }) => (
                    <div
                        style={{
                            padding: 8,
                        }}
                        onKeyDown={(e) => e.stopPropagation()}
                    >
                        <Input
                            ref={searchInput}
                            // placeholder={`Search ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={(e) =>
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                            }
                            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            style={{
                                marginBottom: 8,
                                display: "block",
                            }}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{
                                    width: 90,
                                }}
                            >
                                Axtar
                            </Button>
                            <Button
                                onClick={() => clearFilters && handleReset(clearFilters)}
                                size="small"
                                style={{
                                    width: 90,
                                }}
                            >
                                Sıfırla
                            </Button>
                            <Button
                                type="link"
                                size="small"
                                onClick={() => {
                                    close();
                                }}
                            >
                                Bağla
                            </Button>
                        </Space>
                    </div>
                ),
                filterIcon: (filtered) => (
                    <SearchOutlined
                        style={{
                            color: filtered ? "#1677ff" : undefined,
                        }}
                    />
                ),
                onFilter: (value, record) => {
                    const recordValue = record[dataIndex];
                    if (recordValue == null) {
                        return false; // or handle as needed
                    }
                    return normalizeText(recordValue.toString()).includes(normalizeText(value));
                },
                onFilterDropdownOpenChange: (visible) => {
                    if (visible) {
                        setTimeout(() => searchInput.current?.select(), 100);
                    }
                },
                render: (text) => <>{dataIndex == "approveDate" ? dateForRequest(text) : text}</>,
            };
        }
    };
    const columns = [
        {
            title: "Adı",
            dataIndex: "equipmentName",
            ...getColumnSearchProps("equipmentName"),
            sorter: (a, b) => a.equipmentName?.localeCompare(b.equipmentName),
        },
        {
            title: "Sayı",
            dataIndex: "attachedCount",
            ...getColumnSearchProps("attachedCount"),
            width: 140,
            sorter: (a, b) => a.attachedCount - b.attachedCount,
        },
        {
            title: "Yerləşmə yeri",
            dataIndex: "equipmentLocationName",
            ...getColumnSearchProps("equipmentLocationName"),
            sorter: (a, b) => a.equipmentLocationName?.localeCompare(b.equipmentLocationName),
        },
        {
            title: "Vəziyyəti",
            dataIndex: "equipmentConditionName",
            ...getColumnSearchProps("equipmentConditionName"),
            width: 200,
            sorter: (a, b) => a.equipmentConditionName?.localeCompare(b.equipmentConditionName),
        },
        {
            title: "Təsdiq edilib?",
            dataIndex: "approvedBy",
            ...getColumnSearchProps("approvedBy"),
            width: 140,
            sorter: (a, b) => a.approvedBy?.localeCompare(b.approvedBy),
        },
        {
            title: "Təsdiq tarixi",
            dataIndex: "approveDate",
            width: 140,
            sorter: (a, b) => a.approveDate?.localeCompare(b.approveDate),
            ...getColumnSearchProps("approveDate"),
        },
        {
            title: "Əməliyyatlar",
            key: "action",
            width: 140,
            render: (info, record) => (
                <div style={{ display: "flex" }} className="commonButton">
                    <div style={{ display: "flex", gap: "10px" }}>
                        {!record.approvedBy ? (
                            <Popconfirm
                                onConfirm={() => handleApprove(info.id)}
                                title="Təsdiq edilsin?"
                                okText="Bəli"
                                cancelText="Xeyr"
                            >
                                <Button
                                    disabled={!roles.includes(AttachmentApprove)}
                                    icon={<CheckCircle />}
                                ></Button>
                            </Popconfirm>
                        ) : (
                            <div style={{ width: "32px" }}></div>
                        )}

                        <Button
                            type="primary"
                            className="table_edit_button"
                            onClick={() => {
                                setIsVisible(true);
                                setEditData(info);
                            }}
                            disabled={!roles.includes(AttachmentUpdate)}
                            icon={<FileEditIcon className="table_edit_icon" />}
                        ></Button>
                        <Popconfirm
                            onConfirm={() =>
                                deleteHandler(info.id, urls.attachedEquipment, getAll, "demand")
                            }
                            title="Məlumat silinsin？"
                            okText="Bəli"
                            cancelText="Xeyr"
                        >
                            <Button
                                disabled={!roles.includes(AttachmentDelete)}
                                type="primary"
                                danger
                                icon={<Trash2 />}
                            ></Button>
                        </Popconfirm>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>
            <PageHeader
                setIsVisible={setIsVisible}
                topText="Əsas vəsaitlər"
                bottomText="Əsas vəsaitlərin siyahısı"
                roles={!roles.includes(AttachmentAdd)}
            />
            <MainResourceSearch
                setTableData={setData}
                setSelectedEmp={setSelectedEmp}
                selectedStr={selectedStr}
                setSelectedStr={setSelectedStr}
            />

            <Table columns={columns} dataSource={data} rowKey="id" size="middle" />

            <MainResourceModal
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                editData={editData}
                setEditData={setEditData}
                getAll={getAll}
                selectedStr={selectedStr}
                setSelectedStr={setSelectedStr}
            />
        </>
    );
}

export default MainResource;
