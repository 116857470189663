import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../../components/common/PageHeader";
import { urls } from "../../../config/urls";
import { Button, Popconfirm, Table, Tooltip } from "antd";
import AddModal from "./AddModal";
import { getColumnSearchProps } from "../../../utils/filterTable";
import { httpClient } from "../../../config/config";
import { FileEditIcon, MousePointerSquare, Trash2 } from "lucide-react";
import EquipmentSearch from "./EquipmentSearch";

function Equipments({ setSelectedEqpm, isEqpmVisible, setIsEqpmVisible }) {
    const [data, setData] = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [editData, setEditData] = useState();
    const searchInput = useRef(null);

    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalSize, setTotalSize] = useState(0);

    const [searchText, setSearchText] = useState();
    const [searchCase, setSearchCase] = useState(false);

    useEffect(() => {
        if (searchCase) {
            setPageIndex(0);
            searchWithName(pageSize, 1);
        }
        setSearchCase(false);
    }, [searchCase]);

    useEffect(() => {
        getAll();
    }, []);

    const removeSearch = (form) => {
        setSearchText();
        form.resetFields();
        setPageIndex(0);
        setPageSize(10);
        getAll(10, 0);
    };

    const deleteHandler = (id) => {
        httpClient({ url: urls.equipments + "/" + id, method: "delete" }).then(() =>
            getAll(null, null, false, true)
        );
    };

    const columns = [
        {
            title: "Adı",
            dataIndex: "name",
            // ...getColumnSearchProps("name", searchInput),
            sorter: (a, b) => a?.name?.localeCompare(b?.name),
        },
        {
            title: "Növü",
            dataIndex: "equipmentType",
            // ...getColumnSearchProps("equipmentType", searchInput),
            sorter: (a, b) => a?.equipmentType?.localeCompare(b?.equipmentType),
            width: 220,
        },
        {
            title: "Ölçü vahidi",
            dataIndex: "measureType",
            // ...getColumnSearchProps("measureType", searchInput),
            sorter: (a, b) => a?.measureType?.localeCompare(b?.measureType),
            width: 180,
        },
        {
            title: "Əməliyyatlar",
            key: "action",
            width: 140,
            render: (info, record) => (
                <div style={{ display: "flex" }} className="commonButton gap_10">
                    {isEqpmVisible && (
                        <Tooltip title="Seçin">
                            <Button
                                onClick={() => {
                                    setSelectedEqpm(record);
                                    setIsEqpmVisible(false);
                                }}
                                icon={<MousePointerSquare />}
                            ></Button>
                        </Tooltip>
                    )}
                    <Button
                        type="primary"
                        onClick={() => {
                            setIsVisible(true);
                            setEditData(info);
                        }}
                        // disabled={!roles.includes(ProductListAddEdit)}
                        icon={<FileEditIcon />}
                    ></Button>
                    <Popconfirm
                        onConfirm={() => deleteHandler(info.id)}
                        title="Məlumat silinsin"
                        okText="Bəli"
                        cancelText="Xeyr"
                    >
                        <Button
                            type="primary"
                            danger
                            icon={<Trash2 />}
                            // disabled={!roles.includes(ProductListAddEdit)}
                        ></Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const searchWithName = (size, page) => {
        httpClient({
            url:
                urls.equipments +
                "/name/" +
                searchText +
                `?page=${page ? page - 1 : pageIndex}&size=${size ? size : pageSize}`,
            method: "get",
        }).then((res) => {
            setData(res.data.data?.equipments);
            setTotalSize(res.data.data?.recordCount);
        });
    };

    const getAll = (size, page, resetSearch, update) => {
        if (resetSearch) {
            setPageIndex(0);
            setSearchText();
        }
        if (update && searchText) {
            searchWithName();
        } else {
            httpClient({
                url:
                    urls.equipment_actives +
                    `?page=${resetSearch ? 0 : page ? page - 1 : pageIndex}&size=${
                        size ? size : pageSize
                    }`,
                method: "get",
            }).then((res) => {
                setData(res.data.data?.equipments);
                setTotalSize(res.data.data?.recordCount);
            });
        }
    };

    const changePage = (pagination, filters, sorter, extra) => {
        const isPaginationChange = extra.action === "paginate";
        if (isPaginationChange) {
            setPageSize(pagination.pageSize);
            setPageIndex(pagination.current - 1);
            if (searchText) {
                searchWithName(pagination.pageSize, pagination.current);
            } else {
                getAll(pagination.pageSize, pagination.current);
            }
        }
    };

    return (
        <>
            <PageHeader
                setIsVisible={setIsVisible}
                topText="Vəsaitlərin siyahısı"
                bottomText="Vəsaitlərin ümumi siyahısı"
            />
            <EquipmentSearch
                removeSearch={removeSearch}
                setSearchCase={setSearchCase}
                setSearchText={setSearchText}
            />
            <Table
                columns={columns}
                dataSource={data}
                size="small"
                pagination={{
                    defaultPageSize: pageSize,
                    pageSize: pageSize,
                    defaultCurrent: 1,
                    current: pageIndex == 0 ? 1 : pageIndex + 1,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50"],
                    total: totalSize,
                }}
                onChange={changePage}
                rowKey="id"
            />
            <AddModal
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                editData={editData}
                setEditData={setEditData}
                getAll={getAll}
            />
        </>
    );
}

export default Equipments;
