export const urls = {
    positions_actives: "positions/actives",
    positions: "positions",
    orderStatus_actives: "order-status/actives",
    orderStatus: "order-status",
    measureType_actives: "measure-type/actives",
    measureType: "measure-type",
    structures_actives: "structures/actives",
    structures_main_actives: "structures/main-actives",
    structures: "structures",
    docType_actives: "doc-type/actives",
    docType: "doc-type",
    staffUnits: "staff-units",
    staffUnits_actives: "staff-units/actives",
    employees: "employees",
    employees_actives: "employees/actives",
    employee_position: "employee-positions",
    employee_position_actives: "employee-positions/actives",
    demandOrders: "demand-orders",
    demandOrders_actives: "demand-orders/actives",
    demandOrdersList: "demand-order-list",
    demandOrdersList_actives: "demand-order-list/actives",
    demandStatusById: "demand-order-status/demand-order/",
    demandStatus_actives: "demand-order-status/actives",
    demandStatus: "demand-order-status",
    orderFileList: "order-file-list",
    users: "api/user",
    usersAddPrivileges: "api/user/addPrivileges",
    usersGetAll: "api/user/getAll",
    roles: "api/roles",
    employeePhotos: "employee-photos",
    attachedEquipment: "attached-equipment",
    contagentList: "contragent-list",
    products: "products",
    products_actives: "products/actives",
    equipmentType: "equipment-type",
    equipmentType_actives: "equipment-type/actives",
    equipmentCondition: "equipment-condition",
    equipmentCondition_actives: "equipment-condition/actives",
    equipmentLocation: "equipment-location",
    equipmentLocation_actives: "equipment-location/actives",
    equipments: "equipments",
    equipment_actives: "equipments/actives",
    orderPriority_actives: "order-priority/actives",
    orderPriority: "order-priority",
    orderPurpose_actives: "order-purpose/actives",
    orderPurpose: "order-purpose",
};

export const esd_urls = {
    deliveryForm: "delivery-form",
    deliveryForm_actives: "delivery-form/actives",
    documentKind: "document-kind",
    documentKind_actives: "document-kind/actives",
    nomenclature: "nomenclature",
    nomenclature_actives: "nomenclature/actives",
    documentType: "document-type",
    documentType_actives: "document-type/actives",
    externalPerson: "external-person",
    externalPerson_actives: "external-person/actives",
    externalStructure: "external-structure",
    externalStructure_actives: "external-structure/actives",
    document: "document",
    document_actives: "document/actives",
    documentFile: "document-file",
    derkenarTemplate: "derkenar-template",
    derkenarSendPerm: "derkenar-sendperm",
    curation: "curation",
    documentOperation: "document-operation",
    operationType: "operation-type",
};
