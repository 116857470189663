import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar1/Sidebar";
import Header from "../Header/Header";
function DashboardLayout() {
  return (
    <>
      <div className="App">
        <div className="main_left">
          <Sidebar />
        </div>
        <div className="main_right">
          <div className="main_right_header">
            <Header />
          </div>
          <div style={{ padding: "10px 40px" }}>
            <section style={{ width: "100%", margin: "0 auto" }}>
              <Outlet></Outlet>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardLayout;
