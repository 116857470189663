import { useEffect } from "react";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../routes/routes";
import { ChevronRight } from "lucide-react";
import { useSelector } from "react-redux";
import "./menuList.scss"
import { roleMenuRouteConfig } from "../../../pages/auth/roles";

export default function MenuList() {
    let path = window?.location?.pathname?.split("/")[1];
    const roles = useSelector((state) => state.auth.roles);

    function isAccessible(userRoles, routeKey) {
        const rolesWithAccess = Object.entries(roleMenuRouteConfig).reduce((acc, [role, routes]) => {
            if (routes.includes(routeKey)) {
                acc.push(role);
            }
            return acc;
        }, []);
    
        return rolesWithAccess.length === 0 || rolesWithAccess.some(role => userRoles.includes(role));
    }

    const filteredRoutes = routes.filter(route => 
        isAccessible(roles, route.key) && (route.key === path || route.key === "")
    ).map(route => ({
        ...route,
        children: route.children.filter(child => isAccessible(roles, child.key))
    }));

    


    return (
        <>
            {filteredRoutes.map(route => (
                <Fragment key={route.key}>
                    {route.key === path && route.children.length > 0 && (
                        <Fragment key={route.key}>
                            <div className="settings_main" key={route.name}>
                                <h2>{route.name}</h2>
                                <div className="settings_part">
                                    <ul>
                                        {route.children.map((child, index) => (
                                            <Link key={index} to={`/${child.key}`}>
                                                <li>
                                                    <h4>{child.name}</h4>
                                                    <ChevronRight />
                                                </li>
                                            </Link>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            ))}
        </>
    );
}
