import { Button, Col, Image, Modal, Row } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import styles from "./employee.module.css";
import UploadImage from "./UploadImage";
import { httpClient } from "../../config/config";
import { urls } from "../../config/urls";
function EmployeePdfModal({ pdfVisible, setPdfVisible, editData }) {
    const [base64File, setBase64File] = useState();
    const onCancel = () => {
        setPdfVisible(false);
    };
    useEffect(() => {
        if (editData) {
            httpClient({
                url: urls.employeePhotos + "/employee/" + editData.employeeId,
                method: "get",
            }).then((res) => {
                setBase64File(res.data?.data[0]?.filePath);
            });
        }
    }, [editData]);
    const printDocument = () => {
        const input = document.getElementById("divToPrint");
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF({ unit: "px", format: [600, 536] });
            pdf.addImage(imgData, "JPEG", 0, 0);
            pdf.save("employee.pdf");
        });
    };
    return (
        <Modal
            title={"Pdf çapı"}
            open={pdfVisible}
            footer={null}
            onCancel={onCancel}
            destroyOnClose={true}
            maskClosable={false}
            width={1000}
        >
            <div id="divToPrint" style={{ padding: "10px" }}>
                <div className={styles.common}>
                    <div className={styles.pdf}>
                        <div className={styles.title}>İşçi barədə məlumat</div>
                        <Row>
                            <Col span={8}>
                                <div
                                    className={styles.image_container}
                                    style={base64File && { border: "none" }}
                                >
                                    {base64File ? (
                                        <Image src={"data:image/png;base64, " + base64File} />
                                    ) : (
                                        "Şəkil yoxdur"
                                    )}
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={styles.text}>
                                    Ad: <span>{editData?.employeeName}</span>
                                </div>
                                <div className={styles.text}>
                                    Soyad: <span>{editData?.employeeSurname}</span>
                                </div>
                                <div className={styles.text}>
                                    Ata adı: <span>{editData?.employeePatronymic}</span>
                                </div>
                                <div className={styles.text}>
                                    Doğum tarixi: <span>{editData?.employeeName}</span>
                                </div>
                                <div className={styles.text}>
                                    Fin kod <span>{editData?.employeeName}</span>
                                </div>
                                <div className={styles.text}>
                                    SSN: <span>{editData?.employeeName}</span>
                                </div>
                            </Col>
                        </Row>
                        <div className={styles.title}>İş yeri barədə məlumat</div>
                        <Row gutter={16} style={{ marginLeft: "26px" }}>
                            <Col span={12}>
                                <div className={styles.text}>
                                    İş yeri: <span>{editData?.strName}</span>
                                </div>
                                <div className={styles.text}>
                                    Vəzifə: <span>{editData?.positionName}</span>
                                </div>
                                <div className={styles.text}>
                                    Müqavilə nömrəsi: <span>{editData?.contractNumber}</span>
                                </div>
                                <div className={styles.text}>
                                    Əmək haqqı: <span>{editData?.salary}</span>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className={styles.text}>
                                    Müqavilə tarixi: <span>{editData?.contractDate}</span>
                                </div>
                                <div className={styles.text}>
                                    Müqavilənin başlama tarixi:{" "}
                                    <span>{editData?.contractStartDate}</span>
                                </div>
                                <div className={styles.text}>
                                    Müqavilənin bitmə tarixi:{" "}
                                    <span>{editData?.contractEndDate}</span>
                                </div>
                                <div className={styles.text}>
                                    İşə başlama tarixi: <span>{editData?.workStartDate}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="center_items gap_20">
                <Button type="primary" onClick={printDocument}>
                    Pdf yüklə
                </Button>
            </div>
        </Modal>
    );
}

export default EmployeePdfModal;
