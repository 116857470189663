import React, { useState } from "react";
import { routes } from "../../routes/routes";
import { Link } from "react-router-dom";
import { AlignJustifyIcon, Lock, LogOut } from "lucide-react";
import { Button, Modal } from "antd";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { DictionaryView, UserSettings } from "../../../pages/auth/roles";
import "./sidebar.scss";
import Password from "./Password";

const Sidebar = () => {
    const [logOutModal, setLogOutModal] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const roles = useSelector((state) => state.auth.roles);

    const handleLogout = () => {
        Cookies.remove("apf_refresh_token");
        Cookies.remove("apf_access_token");
        window.location.replace("/login");
    };
    return (
        <>
            <div className="sidebar_button">
                <AlignJustifyIcon style={{ color: "white", fontSize: "33px" }} />
            </div>
            <div className="sidebar_menu">
                <ul className="sidebar_list">
                    {routes.map((item) => {
                        if (item.key == "/users") {
                            if (roles?.includes(UserSettings)) {
                                return (
                                    <li key={item.key}>
                                        <Link to={item.key}>
                                            <div
                                                className="sidebar_list_main_item"
                                                style={{ fontSize: "20px" }}
                                            >
                                                {item.icon}
                                            </div>
                                        </Link>
                                    </li>
                                );
                            }
                        } else if (item.key == "settings") {
                            if (roles?.includes(DictionaryView)) {
                                return (
                                    <li key={item.key}>
                                        <Link to={item.key}>
                                            <div
                                                className="sidebar_list_main_item"
                                                style={{ fontSize: "20px" }}
                                            >
                                                {item.icon}
                                            </div>
                                        </Link>
                                    </li>
                                );
                            }
                        } else {
                            return (
                                <li key={item.key}>
                                    <Link to={item.key}>
                                        <div
                                            className="sidebar_list_main_item"
                                            style={{ fontSize: "20px" }}
                                        >
                                            {item.icon}
                                        </div>
                                    </Link>
                                </li>
                            );
                        }
                    })}
                </ul>

                <ul className="sidebar_list_bottom">
                    <li onClick={() => setIsVisible(true)}>
                        <div className="sidebar_list_main_item">
                            <div>
                                <Lock />
                            </div>
                        </div>
                    </li>
                    <li onClick={() => setLogOutModal(true)}>
                        <div className="sidebar_list_main_item">
                            <div>
                                <LogOut />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <Modal centered onCancel={() => setLogOutModal(false)} open={logOutModal} footer={null}>
                <div className="exit_modal">
                    <h4>Çıxmaq istədiyinizdən əminsiniz?</h4>
                    <div>
                        <Button type="primary" danger onClick={() => setLogOutModal(false)}>
                            Xeyr
                        </Button>
                        <Button
                            type="default"
                            style={{ color: "#4caf50", border: "1px solid #4caf50" }}
                            onClick={handleLogout}
                        >
                            Bəli
                        </Button>
                    </div>
                </div>
            </Modal>

            <Password isVisible={isVisible} setIsVisible={setIsVisible} />
        </>
    );
};

export default Sidebar;
