import React from "react";
import { Button, Popconfirm } from "antd";
import { FileEditIcon, Trash2 } from "lucide-react";
import { deleteHandler } from "../../utils/deleteUtils";

function TableButton({
    setIsVisible,
    setEditData,
    info,
    id,
    url,
    getAll,
    type,
    customComps,
    editRole,
    deleteRole,
    hideDeleteButton,
}) {
    return (
        <div style={{ display: "flex" }} className="commonButton">
            <div style={{ display: "flex", gap: "10px" }}>
                {customComps && customComps}
                <Button
                    type="primary"
                    className="table_edit_button"
                    onClick={() => {
                        setIsVisible(true);
                        setEditData(info);
                    }}
                    disabled={editRole || false}
                    icon={<FileEditIcon className="table_edit_icon" />}
                ></Button>
                {!hideDeleteButton && (
                    <Popconfirm
                        onConfirm={() => deleteHandler(id, url, getAll, type)}
                        title="Məlumat silinsin？"
                        okText="Bəli"
                        cancelText="Xeyr"
                    >
                        <Button
                            disabled={deleteRole || false}
                            type="primary"
                            danger
                            icon={<Trash2 />}
                        ></Button>
                    </Popconfirm>
                )}
            </div>
        </div>
    );
}

export default TableButton;
